var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.title,
            "footer-class": "footerClass",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { hide: _vm.hide },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({}) {
                return [
                  _vm.allowSelect
                    ? [
                        _vm.canEdit() || !_vm.exists
                          ? _c(
                              "b-button",
                              {
                                attrs: { size: "sm", variant: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveAllChanges(true)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.ok")))]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: { click: _vm.hide },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "SELECT" === _vm.mode
                              ? "button.close"
                              : "button.cancel"
                          )
                        )
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              on: { dismissed: _vm.dismissAlert },
              model: {
                value: _vm.errorShow,
                callback: function ($$v) {
                  _vm.errorShow = $$v
                },
                expression: "errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("b-form-radio-group", {
                    staticClass: "preview-state-toggler",
                    attrs: {
                      options: [
                        { text: _vm.$t("comment.button.write"), value: false },
                        { text: _vm.$t("comment.button.preview"), value: true },
                      ],
                      buttons: "",
                      "button-variant": "outline-secondary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.preview,
                      callback: function ($$v) {
                        _vm.preview = $$v
                      },
                      expression: "preview",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "pl-md-0",
                  attrs: { cols: "12", "offset-md": "4", md: "6" },
                },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mt-2 mt-md-0",
                      attrs: {
                        label: _vm.$t("field.identifier"),
                        "label-for": "identifier",
                        "label-align-md": "right",
                        "label-cols-md": "3",
                        "content-cols-md": "9",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "identifier",
                              type: "text",
                              "data-vv-as": _vm.$t("field.identifier"),
                              "data-vv-name": "note.identifier",
                              maxlength: _vm.maxIdentifierLength,
                              disabled: _vm.isReadOnly,
                              size: "",
                              trim: "",
                            },
                            model: {
                              value: _vm.note.identifier,
                              callback: function ($$v) {
                                _vm.$set(_vm.note, "identifier", $$v)
                              },
                              expression: "note.identifier",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.customFieldMap["identifier"] != null
                ? _vm._l(
                    _vm.customFieldMap["identifier"],
                    function (field, index) {
                      return _c(
                        "b-col",
                        { key: "identifier" + index, attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              field.type !== "Boolean"
                                ? _c("template", { slot: "label" }, [
                                    _c("span", { staticClass: "mr-2" }, [
                                      _vm._v(_vm._s(field.displayName)),
                                    ]),
                                    field.description
                                      ? _c(
                                          "span",
                                          [
                                            _c("font-awesome-icon", {
                                              style: {
                                                color:
                                                  "var(--form-control-placeholder)",
                                                fontSize: "0.9em",
                                              },
                                              attrs: {
                                                id: `${_vm.componentId}_${field.name}`,
                                                icon: [
                                                  "far",
                                                  "circle-question",
                                                ],
                                              },
                                            }),
                                            _c(
                                              "b-popover",
                                              {
                                                attrs: {
                                                  target: `${_vm.componentId}_${field.name}`,
                                                  triggers: "hover",
                                                  placement: "top",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(field.description) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _c("CustomField", {
                                attrs: {
                                  componentId: _vm.componentId,
                                  field: field,
                                  disabled:
                                    _vm.isReadOnly ||
                                    (_vm.exists &&
                                      !_vm.canEdit(_vm.permissionName, [
                                        field.name,
                                      ])),
                                },
                                model: {
                                  value: _vm.note[field.name],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.note, field.name, $$v)
                                  },
                                  expression: "note[field.name]",
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    }
                  )
                : _vm._e(),
            ],
            2
          ),
          _c("b-textarea", {
            ref: "textarea",
            staticClass: "comment-textarea rounded-0",
            class: { "d-none": _vm.previewState },
            attrs: {
              refs: "commentInput",
              name: "note",
              id: "message",
              rows: "9",
              placeholder: _vm.$t("comment.placeholder.your_comment"),
              trim: "",
              required: "",
              autofocus: "",
            },
            model: {
              value: _vm.note.text,
              callback: function ($$v) {
                _vm.$set(_vm.note, "text", $$v)
              },
              expression: "note.text",
            },
          }),
          _c("div", {
            staticClass: "preview markdown-body",
            class: { "d-none": !_vm.previewState },
            domProps: { innerHTML: _vm._s(_vm.compiledMarkdown) },
          }),
          _vm.customFieldMap["note"] != null ||
          _vm.customFieldMap["default"] != null
            ? _c(
                "div",
                { staticClass: "container pl-0 mt-3" },
                [
                  _c(
                    "b-row",
                    [
                      _vm.customFieldMap["note"] != null
                        ? _vm._l(
                            _vm.customFieldMap["note"],
                            function (field, index) {
                              return _c(
                                "b-col",
                                {
                                  key: "note" + index,
                                  staticClass: "pr-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      field.type !== "Boolean"
                                        ? _c("template", { slot: "label" }, [
                                            _c(
                                              "span",
                                              { staticClass: "mr-2" },
                                              [
                                                _vm._v(
                                                  _vm._s(field.displayName)
                                                ),
                                              ]
                                            ),
                                            field.description
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("font-awesome-icon", {
                                                      style: {
                                                        color:
                                                          "var(--form-control-placeholder)",
                                                        fontSize: "0.9em",
                                                      },
                                                      attrs: {
                                                        id: `${_vm.componentId}_${field.name}`,
                                                        icon: [
                                                          "far",
                                                          "circle-question",
                                                        ],
                                                      },
                                                    }),
                                                    _c(
                                                      "b-popover",
                                                      {
                                                        attrs: {
                                                          target: `${_vm.componentId}_${field.name}`,
                                                          triggers: "hover",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              field.description
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      _c("CustomField", {
                                        attrs: {
                                          componentId: _vm.componentId,
                                          field: field,
                                          disabled:
                                            _vm.isReadOnly ||
                                            (_vm.exists &&
                                              !_vm.canEdit(_vm.permissionName, [
                                                field.name,
                                              ])),
                                        },
                                        model: {
                                          value: _vm.note[field.name],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.note, field.name, $$v)
                                          },
                                          expression: "note[field.name]",
                                        },
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            }
                          )
                        : _vm._e(),
                      _vm.customFieldMap["default"] != null
                        ? _vm._l(
                            _vm.customFieldMap["default"],
                            function (field, index) {
                              return _c(
                                "b-col",
                                {
                                  key: index,
                                  staticClass: "pr-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      field.type !== "Boolean"
                                        ? _c("template", { slot: "label" }, [
                                            _c(
                                              "span",
                                              { staticClass: "mr-2" },
                                              [
                                                _vm._v(
                                                  _vm._s(field.displayName)
                                                ),
                                              ]
                                            ),
                                            field.description
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("font-awesome-icon", {
                                                      style: {
                                                        color:
                                                          "var(--form-control-placeholder)",
                                                        fontSize: "0.9em",
                                                      },
                                                      attrs: {
                                                        id: `${_vm.componentId}_${field.name}`,
                                                        icon: [
                                                          "far",
                                                          "circle-question",
                                                        ],
                                                      },
                                                    }),
                                                    _c(
                                                      "b-popover",
                                                      {
                                                        attrs: {
                                                          target: `${_vm.componentId}_${field.name}`,
                                                          triggers: "hover",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              field.description
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      _c("CustomField", {
                                        attrs: {
                                          componentId: _vm.componentId,
                                          field: field,
                                          disabled:
                                            _vm.isReadOnly ||
                                            (_vm.exists &&
                                              !_vm.canEdit(_vm.permissionName, [
                                                field.name,
                                              ])),
                                        },
                                        model: {
                                          value: _vm.note[field.name],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.note, field.name, $$v)
                                          },
                                          expression: "note[field.name]",
                                        },
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            }
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "markdown-hint" },
            [
              _c(
                "font-awesome-layers",
                { staticClass: "fa-lg info-icon" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle"], transform: "shrink-2" },
                  }),
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "info"], transform: "shrink-8" },
                  }),
                ],
                1
              ),
              _c("span", [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      href: "https://projectal.com/resources/markdown",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("comment.link.markdown")))]
                ),
                _vm._v(" " + _vm._s(_vm.$t("comment.link.is_supported"))),
              ]),
            ],
            1
          ),
          _vm.canList("NOTE") && _vm.showList
            ? _c("NoteList", {
                staticClass: "mt-2",
                attrs: { notes: _vm.displayNotes },
                on: { add: _vm.add, edit: _vm.edit, toRemove: _vm.toRemove },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.save_comment"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.promptSaveNoteShow = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({}) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmSaveOk(true)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.yes")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmSaveOk(false)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.no")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.promptSaveNoteShow,
            callback: function ($$v) {
              _vm.promptSaveNoteShow = $$v
            },
            expression: "promptSaveNoteShow",
          },
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("comment.save_prompt")))])]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("comment.confirmation.title"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.promptUnsavedChangesShow = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.confirmUnsavedChangeOK },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.promptUnsavedChangesShow,
            callback: function ($$v) {
              _vm.promptUnsavedChangesShow = $$v
            },
            expression: "promptUnsavedChangesShow",
          },
        },
        [
          _c("span", [
            _vm._v(
              _vm._s(_vm.$t("comment.confirmation.content_unsaved_change"))
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("title_save_with_error"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { hidden: _vm.saveErrorOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.saveErrorShow,
            callback: function ($$v) {
              _vm.saveErrorShow = $$v
            },
            expression: "saveErrorShow",
          },
        },
        [
          _c(
            "b-row",
            [
              _vm._l(_vm.saveErrors, function (item, index) {
                return [
                  _c("b-col", { key: index, attrs: { cols: "12" } }, [
                    _vm._v(_vm._s(item)),
                  ]),
                ]
              }),
            ],
            2
          ),
          _c("span", [_vm._v(_vm._s(_vm.$t("comment.save_prompt")))]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }